import React from "react";
import SEO from "components/Seo";
import Hero from "sections/Hero/Hero";
import textImage1 from "data/TextImage1";
import textImage2 from "data/TextImage2";
import textImage3 from "data/TextImage3";
import TextImage from "sections/TextImage/TextImage";
import Benefits from "sections/Benefits/Benefits";
import Cards from "sections/Cards/Cards";
import Baner from "sections/Baner/Baner";

const IndexPage = () => (
  <>
    <SEO title="" description=""/>
    <Hero id="home"/>
    <TextImage swap data={textImage2()}/>
    <Benefits id="benefits"/>
    <TextImage data={textImage3()} reverse/>
    <Baner/>
    <TextImage id="about" data={textImage1()}/>
    <Cards id="products"/>
  </>
);

export default IndexPage;
