import styled from "styled-components";
import {Paragraph, TitleM} from "components/typography/Typography";

export const BenefitBody = styled.div`
    width: calc(33.333% - 34px);
    background: transparent;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:nth-child(3n)) {
        margin-right: 50px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        width: calc(33.333% - 26px);

        &:not(:nth-child(3n)) {
            margin-right: 38px;
        }
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        width: calc(33.333% - 17px);
        border-width: 2px;

        &:not(:nth-child(3n)) {
            margin-right: 25px;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        width: 100%;
        margin-bottom: 0;

        &:not(:nth-child(3n)) {
            margin-right: 0;
            margin-bottom: 48px;
        }
    }
`;

export const ImgWrapper = styled.div`
    width: 82px;
    margin-bottom: 52px;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        width: 62px;
        margin-bottom: 40px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop_small}) {
        width: 54px;
        margin-bottom: 34px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet_big}) {
        width: 46px;
        margin-bottom: 30px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        width: 36px;
        margin-bottom: 24px;
    }
`;

export const Img = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

export const BenefitTitle = styled(TitleM)`
    font-size: 26px;
    color: ${({theme}) => theme.colors.white};
    text-align: center;
    max-width: 190px;
    min-height: 52px;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        font-size: 18px;
        min-height: 36px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        min-height: auto;
    }
`;

export const BenefitDesc = styled(Paragraph)`
    color: ${({theme}) => theme.colors.white};
    max-width: 290px;
    text-align: center;
    margin-top: 25px;
    position: relative;
    font-size: 14px;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        max-width: 210px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        font-size: 10px;
        line-height: 1.5;
        margin-top: 12px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        font-size: 12px;
        max-width: 300px;
    }
`;
