import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import TrainingGold from "assets/img/svg/training-gold.svg";
import Img from "gatsby-image";

export const useImage = () => {
  const query = useStaticQuery(graphql`
      query {
          aboutus: file(relativePath: { eq: "img/text-image_3.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1000, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
      }
  `);
  return query;
};

export const useData = () => {
  const query = useImage();
  const data = {
    icon: TrainingGold,
    title: <><span>Grupa LOCKED TRADE to narzędzie zarówno dla początkujących, jak i doświadczonych inwestorów!</span></>,
    text1: <>Jeśli stawiasz dopiero pierwsze kroki na rynku - <span>grupa na platformie Discord</span> to rozwiązanie dla Ciebie! Jeśli chcesz zgłębić swoją dotychczasową wiedzę i poznać opinie innych traderów - również znajdziesz przydatne i ciekawe informacje w naszej grupie. </>,
    buttonText: "Sprawdź",
    image: <Img fluid={query.aboutus.childImageSharp.fluid}/>,
    slug: "#form",
  };
  return data;
};

export default useData;
