import React from "react";
import Document from "assets/img/svg/document.svg";
import Help from "assets/img/svg/help.svg";
import Chat from "assets/img/svg/chat.svg";

export const sectionData = {
  title: <>Korzyści <span>locked trade discord</span></>
};

export const benefitsData = [
  {
    icon: Document,
    title: "Informacje o rynkach",
    text: "Bądź na bieżąco z aktualnymi nowościami, reaguj na zmieniającą się sytuację na wielu rynkach i pogłębiaj swoją wiedzę."
  },
  {
    icon: Chat,
    title: "Wspólny trading",
    text: "Zdobywaj doświadczenie inwestując  wspólnie z innymi. Rozgrywaj na różnych rynkach wraz z pozostałymi członkami grupy."
  },
  {
    icon: Help,
    title: "Dostęp do strategii",
    text: "Poznaj sposoby inwestowania doświadczonych traderów, które pomogą Ci w skutecznym tradingu oraz korzystaj z dostępnych strategii."
  },
];
