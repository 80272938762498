 import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import TrainingGold from "assets/img/svg/training-gold.svg";
import Img from "gatsby-image";

export const useImage = () => {
  const query = useStaticQuery(graphql`
    query {
      aboutus: file(relativePath: { eq: "img/discord-group.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return query;
};

export const useData = () => {
  const query = useImage();
  const data = {
    icon: TrainingGold,
    title: <><span>Chcesz stać się najlepszym inwestorem? Dołącz do elitarnej społeczności LOCKED TRADE!</span></>,
    text1:
      "Stworzyliśmy zamkniętą grupę, w której znajdziesz zawsze aktualną wiedzę, zagrania na żywo oraz możesz liczyć na pomoc naszych doświadczonych traderów. ",
    buttonText: "Sprawdź",
    image: <Img fluid={query.aboutus.childImageSharp.fluid} />,
    slug: "#form",
  };
  return data;
};

export default useData;
