import styled from "styled-components";
import {GlobalWrapper} from "shared/Styles/Wrappers";
import {TitleL} from "../../components/typography/Typography";

export const Container = styled(GlobalWrapper)`
    background-color: ${({theme}) => theme.colors.black};
    padding: 124px 0;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        padding: 96px 0;
    }
`;

export const StyledTitle = styled(TitleL)`
    color: ${({theme}) => theme.colors.white};
    text-transform: uppercase;

    span {
        font-weight: ${({theme}) => theme.weights.normal};
        font-family: "Rajdhani", sans-serif;
    }
`;

export const BenefitsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 144px 0 0;
    margin: 0 auto;
    justify-content: center;
    max-width: 1150px;
    width: 100%;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        max-width: 920px;
        padding-top: 110px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        max-width: 662px;
        padding-top: 82px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        max-width: 550px;
        padding-top: 64px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        flex-direction: column;
        align-items: center;
        max-width: 320px;
        padding-top: 44px;
    }
`;

export const TitleWrapper = styled.div`
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        padding: 0 36px;
        line-height: 1.2;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        line-height: 1.2;
    }
`;

