import styled from "styled-components";
import {GlobalWrapper} from "shared/Styles/Wrappers";
import {TitleL} from "../../components/typography/Typography";

export const Container = styled(GlobalWrapper)`
    padding: 180px 0;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-color: ${({theme}) => theme.colors.primary};
        opacity: 0.82;
        z-index: -1;
        width: 100%;
        height: 100%;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        padding: 132px 0;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop_small}) {
        padding: 108px 0;
    }
    
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        padding: 84px 0;
    }
`;

export const BackgroundImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
`;

export const Content = styled.div`
    max-width: 960px;
    width: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        padding-left: 24px;
        padding-right: 24px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.phone}) {
        padding-left: 0;
        padding-right: 0;
    }
`;

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
`;

export const IconWrapper = styled.div`
    width: 96px;
    height: 84px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        width: 72px;
        height: 60px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        width: 60px;
        height: 48px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        width: 48px;
        height: 36px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        width: 72px;
        height: 60px;
        margin-bottom: 24px;
        margin-right: 0;
    }
`;

export const Icon = styled.img`
    max-width: 100%;
    max-height: 100%;
    position: relative;
`;

export const StyledTitle = styled(TitleL)`
    color: ${({theme}) => theme.colors.black};

    span {
        font-family: "Rajdhani", sans-serif;
        font-weight: ${({theme}) => theme.weights.less_bold};
    }
`;

export const ButtonWrapper = styled.div`
    margin-top: 36px;
    justify-content: center;
    display: flex;
`;
