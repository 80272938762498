import React from "react";

const data = {
  subTitle: "Dołącz do grupy Locked Trade",
  title: (
    <>
      Na platformie <span>Discord</span>
    </>
  ),
  button: "Sprawdź",
  buttonType: "right_arrow",
  slug: "about",
  id: "hero",
};

export default data;
